<template>
  <div style="width: 100vw;height: 100vh;">
            <img class="loginBackImg" src="../../assets/loginBack.png">
    <div
        style="box-sizing: border-box;padding-left: 60px;padding-right:60px;margin-left: 15vw;width: 33vw;height: 100%;background-color: white;display: flex;flex-direction: column;justify-content: center;">

      <div style="font-family: Montserrat-SemiBold;color: #00116A;font-size: 3.74vw;font-weight: 600;">FBLA Arena</div>
      <div style="font-family: Montserrat-SemiBold;;margin-top: 10px;line-height: 2vw;color: #012C9F;font-size: 1.248vw;font-weight: 400;">2021-2022 FBLA
        China<br>
        National Leadership Conference<br>
        and Economics Challenge
      </div>

      <div class="login-title" style="margin-top: 20px">User Name</div>
      <input class="login-input" placeholder="Please enter your Email" v-model="form.account_name"/>
      <div class="login-title" style="margin-top: 20px">Password</div>
      <input class="login-input" placeholder="Please enter your Password" v-model="form.account_pwd"/>

      <div style="margin-top: 40px;position: relative">
        <div style="position: absolute;right: 0">
          <img @click="submit" src="../../assets/login-arrow.png" style="cursor: pointer;width: 3vw;height: auto">
        </div>

      </div>
      
      <!--            <div class="loginContentContainer">-->
      <!--                <div class="content">-->
      <!--                    <div class="left">-->
      <!--                        <div class="loginTitle">AM Arena</div>-->
      <!--                        <div class="loginInputView">-->
      <!--                            <el-input type="text" class="loginInput" placeholder="Email" v-model="form.account_name"></el-input>-->
      <!--                        </div>-->
      <!--                        <div class="loginInputView">-->
      <!--                            <el-input type="password" class="loginInput" placeholder="Password" v-model="form.account_pwd"></el-input>-->
      <!--                        </div>-->
      <!--                        <div class="loginInputView">-->
      <!--                            <el-select type="text" class="loginInput" placeholder="Identity" v-model="form.type" style="width:100%">-->
      <!--                                <el-option value="学生" label="Student"></el-option>-->
      <!--&lt;!&ndash;                                <el-option value="讲师" label="Lecturer"></el-option>&ndash;&gt;-->
      <!--&lt;!&ndash;                                <el-option value="助教" label="Teaching Assistant"></el-option>&ndash;&gt;-->
      <!--                            </el-select>-->
      <!--                        </div>-->
      <!--                        <div class="loginBtn" @click="submit">Login</div>-->
      <!--                    </div>-->
      <!--                    <div class="right">-->
      <!--                        <img src="../../assets/logo.png" class="logo">-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->

    </div>
  </div>
</template>

<script>
import {loginByEducator, loginByStudent, loginByEmployee} from '../../api/index'
import {
  setUserId,
  setUserName,
  setUserType,
  getProjectCode,
  setApplicationExtraEvents,
  setAppId,
    setAccountName,
  setApplicationEvents, setAvatarUrl,logout
} from '../../utils/store'
import '../../assets/common/font.css'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        account_name: '',
        account_pwd: '',
        type: '学生'
      }
    }
  },
  mounted() {
    //先清除一下数据
    logout()
  },
  methods: {
    submit() {
      if (!this.form.account_name) {
        this.$message.error('Please input email')
        return
      }

      if (!this.form.account_pwd) {
        this.$message.error('Please input password')
        return
      }

      if (this.form.type === '讲师') {
        loginByEducator(this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            setUserId(res.data.data._id.$id)
            setUserName(res.data.data.educator_first_name + res.data.data.educator_last_name)
            setUserType('educator')
            this.$router.push('/teacherlist')
          } else {
            this.$message.warning('login fail')
          }
        })
      } else if (this.form.type === '学生') {
        loginByStudent(getProjectCode(), this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            var result = res.data.data
            var application_extra_events= result.application_extra_events
            setApplicationExtraEvents(application_extra_events)
            var application_events= result.application_events
            setApplicationEvents(application_events)
            var app_id = result._id.$id
            setAppId(app_id)
            var account_name = result['student_info']['account_name']
            setAccountName(account_name)
            var avatar_url = result['student_info']['avatar_url']
            setAvatarUrl(avatar_url)
            setUserId(res.data.data.student_id)
            setUserType('student')
            var student_name = res.data.data.student_info.student_lastName + res.data.data.student_info.student_givenName
            setUserName(student_name)
            this.$router.push('/home')
          } else {
            this.$message.warning('Login failed: ' + res.data.msg)
          }
        })
      } else if (this.form.type === '助教') {
        loginByEmployee(this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            setUserId(res.data.data._id.$id)
            setUserType('employee')
            setUserName(res.data.data.user_lastName + res.data.data.user_givenName)
            this.$router.push('/employeelist')
          } else {
            this.$message.warning('login fail')
          }
        })
      }

    }
  }
}
</script>

<style lang="less">

.loginBackImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
}

.login-title {
  color: #00309F;
  font-size: 0.728vw;
  font-weight: 400;
  font-family: "PingFang SC";
}
.login-input {
  margin-top: 10px;
  box-sizing: border-box;
  border: 0px solid transparent;
  border-bottom-width: 2px;
  border-bottom-color:#012C9F;
  color: #012C9F;
  font-size: 1.1vw;
  width: 100%;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 0px;
  padding: 0 15px;
}


.el-select-dropdown__item.selected {
  color: rgb(243, 152, 0);
  font-weight: 700;
}

.loginContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loginBackImg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
  }

  .loginContentView {
    width: 70%;
    height: 70%;
    background: rgba(255, 255, 255);
    border-radius: 28px;
    position: relative;

    .loginContentContainer {
      width: 100%;
      position: absolute;
      z-index: 3;

      .content {
        display: flex;
        width: 90%;
        margin: 0 auto;

        .left {
          width: 50%;
          position: relative;

          .loginTitle {
            font-size: 4vh;
            margin-top: 10vh;
            text-align: center;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #002A38;
          }

          .loginInputView {
            margin: 5vh auto;
            width: 72%;
          }

          .loginBtn {
            width: 72%;
            height: 5vh;
            line-height: 5vh;
            text-align: center;
            margin: 6vh auto;
            background: #E74646;
            border-radius: 10px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            font-size: 2vh;
            cursor: pointer;
          }

          .loginBtn:hover {
            opacity: 0.8;
          }

          .el-input__inner {
            border-radius: 10px !important;
            /* border: none; */
            height: 5vh;
            line-height: 5vh;
            font-size: 2vh;
            padding: 0 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
          }

          .el-input.is-active .el-input__inner, .el-input__inner:focus {
            border-color: rgb(243, 152, 0);
            outline: 0;
          }

          .el-input__icon {
            line-height: 5vh;
          }
        }

        .right {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .logo {
            width: 60%;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }

    .loginContentImg {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
    }
  }
}

</style>
